<template>
  <div>
    <page-title :heading="$t('settings.lang_externalApps')" :subheading="$t('generic.lang_create') " :icon=icon :show-previous-button="true" ></page-title>
    <div class="app-main__inner">
      <CreateExternalAPPSComponent/>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle.vue";
import CreateExternalAPPSComponent
  from "../../../../components/settings/moduleSettings/externalapss/CreateExternalAPPSComponent";

export default {
  components: {
    CreateExternalAPPSComponent,
    PageTitle,
  },

  data: () => ({
    icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
  })
}
</script>