<template>
  <v-container>
    <v-card>
      <v-card-title>
        {{$t('settings.lang_addExternalApp')}}
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-form lazy-validation ref="externalAPPForm" v-model="valid">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                          @focus="showTouchKeyboard"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL" v-model="name" :disabled="loading" outlined  :rules="[rules.required]" aria-autocomplete="false"
                          :label="$t('generic.lang_name')"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                          @focus="showTouchKeyboard"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL" v-model="target" :disabled="loading" outlined  :rules="[rules.https,rules.required]" aria-autocomplete="false"
                          :label="$t('settings.lang_targetUrl')"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                          @focus="showTouchKeyboard"
                          :data-layout="KEYBOARD.KEYSETS.NUMERIC" v-model="sort" type="number" :rules="[rules.required]" :disabled="loading" outlined aria-autocomplete="false"
                          :label="$t('generic.lang_sorting')"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-switch v-model="status" :disabled="loading" outlined
                        :label="$t('generic.lang_enable')">

              </v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions :class="{'d-block':$vuetify.breakpoint.xs,'text-right':true}">
        <v-spacer/>
        <v-btn color="success" :disabled="loading || !valid" :loading="loading" @click="saveData">{{
            $t('generic.lang_save')
          }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard class="internalWidth" id="onScreenKeyboard" :options="touchKeyboard.options" :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible" :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                          :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
    </div>
  </v-container>
</template>

<script>
import {ENDPOINTS} from "../../../../config";
import {Events} from "../../../../plugins/events";
import validation from "../../../../mixins/validation/validation";
import mixin from '../../../../mixins/KeyboardMixIns'
export default {
  name: "CreateExternalAPPSComponent",
  mixins:[validation,mixin],
  data() {
    return {
      loading: false,
      name:'',
      target:'',
      sort:null,
      status:false,
      valid:true,
    }
  },
  computed:{
  },
  methods: {
    saveData() {

      if(!this.$refs.externalAPPForm.validate()){
        return;
      }

      this.loading = true;

      this.axios.post(ENDPOINTS.SETTINGS.MODULESETTINGS.EXTERNALAPPS.CREATE, {
        name: this.name,
        target: this.target,
        sort:this.sort,
        active:this.status,
      }).then((res) => {
        if (res.data.status==="SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
          this.$refs.externalAPPForm.reset();
        } else {
          Events.$emit("showSnackbar", {
            message: res.data.msg || this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred')+' : '+err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>